.table-header {
  padding-bottom: var(--spacing-7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-color-contrast);
}

.table {
  width: 100%;
  overflow-x: scroll;
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    background-color: white;

    th {
      font-weight: 300;
    }

    .edit-section {
      min-width: 150px;
    }

    .button {
      float: right;
      margin-right: var(--spacing-7);
    }
  }
}
