@tailwind base;
@tailwind components;
@tailwind utilities;
@import "ngx-toastr/toastr";
@import "@angular/cdk/overlay-prebuilt.css";

@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/animations/perspective-extreme.css";

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme customization
@import "theme/theme";
@import "theme/components/buttons";
@import "theme/components/card";
@import "theme/components/table";
@import "theme/helpers";
@import "theme/typography";

@import "ngx-tagify/styles/tagify";

html,
body {
  font-family: "Inter Tight", sans-serif;
  display: flex;
  color: var(--font-color);
  flex-direction: column;
  margin: 0;
  background-color: var(--background-color);

  // til framtidige meg: ikke legg til height 100% her. Det ødelegger scrollTop som er satt opp i app-routing.module
  // til fremtidige mer: ikke legg inn overflow auto her. Det fucker med iphone og ipads..
}

pre {
  font-family: "Inter Tight", sans-serif;
}

input {
  font-weight: 300;
}

.btn {
  font-weight: 500;
}

.textLogo {
  font-family: "Big Shoulders Stencil Display", sans-serif;
  font-size: 60px;
  color: var(--primary-color);
  font-weight: 500;
}

.tab-active {
  border-color: var(--primary-color) !important;
}

// fikser bug i datepickeren, må ligge her for hvis ikke så bygges det ikke riktig..
.datepicker.active.hidden {
  display: none !important;
}

cdk-dialog-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  animation: custom-dialog-enter 300ms ease;

  min-width: 100%;
  max-height: 90vh !important;
  // height: 90vh !important; // tok vekk denne for at dialogen i feks block table skal bli fin
  margin: 0 auto;
  margin-top: 20px;

  overflow: auto;

  position: relative; // for å støtte absolute posisjonering av kryss i hjørne i noen av dialogene

  @keyframes custom-dialog-enter {
    from {
      transform: scale(0);
    }

    to {
      transform: none;
    }
  }
}

.highcharts-tooltip > span {
  background-color: white;
  opacity: 1;

  border: 3px solid var(--primary-color);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 0px 20px black;

  font-size: 1.2em;
}

#sentry-feedback::after {
  position: relative;
}

.datepicker-grid {
  width: 100% !important;
  max-width: 330px !important;
}

.datepicker-cell.selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.datepicker-cell {
  font-size: 1.2rem !important;
  font-weight: 300 !important;
}
.datepicker-view .dow {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.drag-handle {
  background-color: grey !important;
  border: 1px solid red;
}

ngx-material-intl-tel-input,
.cdk-overlay-connected-position-bounding-box {
  .contains-mat-select-search,
  .tel-mat-select-pane,
  .mat-mdc-tooltip-panel,
  .tel-form {
    background-color: white !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .country-option,
  .mdc-text-field__input {
    font-size: 16px !important;
    font-weight: 300 !important;
  }

  .mdc-floating-label,
  mat-hint,
  .mat-mdc-tooltip-panel,
  .main-label {
    display: none !important;
  }
}

.container {
  width: 100% !important;
  max-width: 100% !important;
}

.card {
  border: 1px solid #f5f5f5;
}

:root {
  --tagify-dd-color-primary: var(--primary-color);
  --tagify-dd-item-pad: 8px;
}

.tagify {
  --tags-border-color: var(--border-color);
  --tag-bg: var(--primary-color);
  --tag-text-color: white;
  --tag-pad: 5px;
  --tag-border-radius: 8px;

  min-width: 210px !important;
  height: 48px !important;
  border-radius: 8px;
  border-color: var(--border-color);
}

.customer-tag {
  background-color: var(--primary-color);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.close-button-dialog {
  background-color: white;
  height: 40px;
  width: 40px;
  border: 1px solid #e7e7e7;
  border-radius: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
