@import "media-queries";

.container {
  background-color: var(--background-color-container);
  padding: 20px;
}

.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  background-color: var(--background-color);

  .container {
    margin-top: 20px;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--negative-color);
}

.positive {
  color: var(--positive-color);
}

.negative {
  color: var(--negative-color);
}

.loading-small {
  font-size: 22px;
}

/** hide for normal screen, visible for screen readers. Often when label is needed when the visual is "the voice"*/
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.form-control-error {
  color: var(--negative-color);
}

.gradientElement {
  filter: blur(80px) opacity(10%);
  --left: 50%;
  --width: 50%;
  --max-width: 50%;
  width: 30%;
  height: 30%;
  position: absolute;
  right: 0px;
  bottom: 100px;

  background: rgb(29, 208, 156);
  background: linear-gradient(
    135deg,
    rgb(6, 147, 227) 0%,
    rgb(155, 81, 224) 100%
  );
  z-index: 0;
  pointer-events: none;
}
