@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

/**
  color variables etc.
  plain css to avoid imports in all scss files
  */
:root {
  /** Colors */

  --primary-color: #35b8be;
  --primary-color-dark: #2e9da2;
  --primary-hover-color: rgba(53, 184, 190, 0.76);

  --primary-color-contrast: black;
  --secondary-color: #8035be;
  --secondary-color-contrast: #1d1c11;

  --primary-variant: #3f51b5;

  --link-color: #1f76c2;

  --item-selected: #cce5ff;
  --item-active-border-color: #2196f3;
  --item-hover: #eceff8;

  --border-color: #e3e3e8;
  --font-color: #113335;
  --secondary-font-color: #444444;
  --error-color: #be3b35;
  --negative-color: #be3b35;

  --warning-color: #ff9800;

  --sub-title-color: #707070;

  --table-top-background-color: #ffffff;
  --background-color: #fff;
  --background-color-container: #fdfdfd;
  --secondary-background-color: #f6f6f6;
  --secondary-border-color: #e4e4e4;

  --positive-color: #00b268;
  --positive-color-hover: #00a753;
  --positive-color-10: rgba(0, 222, 19, 0.1);
  --positive-color-30: rgba(0, 222, 19, 0.3);

  --booking-requested: #e5653f;
  --booking-confirmed: var(--primary-color);
  --booking-arrived: #8035be;
  --booking-seated: #35be60;
  --booking-noshow: #e5653f;
  --booking-needscleaning: #ff9800;
  --booking-completed: #e7e7e7;

  --border-radius-standard: 4px;
  --border-radius-large: 15px;

  --primary-color-50: rgba(231, 227, 208, 0.5);

  --mat-gray: #f2f2f5;

  --input-height: 42px;

  --transition-in: 0.5;
  --transition-out: 0.3;
  --hover-scale: 1.035;
  --box-shadow-cards: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  /**
  * Spacings
  * Based on: https://tailwindcss.com/docs/customizing-spacing
  **/
  --spacing-0: 0; /* 0px */
  --spacing-0-5: 0.125rem; /* 2px */
  --spacing-1: 0.25rem; /* 4px */
  --spacing-1-5: 0.375rem; /* 6px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-3: 0.625rem; /* 10px */
  --spacing-3-5: 0.75rem; /* 12px */
  --spacing-4: 0.875rem; /* 14px */
  --spacing-5: 1rem; /* 16px */
  --spacing-6: 1.25rem; /* 20px */
  --spacing-7: 1.5rem; /* 24px */
  --spacing-8: 1.75rem; /* 28px */
  --spacing-9: 2rem; /* 32px */
  --spacing-10: 2.25rem; /* 36px */
  --spacing-11: 2.5rem; /* 40px */
  --spacing-12: 2.75rem; /* 44px */
  --spacing-14: 3rem; /* 48px */
  --spacing-16: 3.5rem; /* 56px */
  --spacing-18: 3.7rem; /* 56px */
  --spacing-20: 4rem; /* 64px */
  --spacing-24: 5rem; /* 80px */
  --spacing-28: 6rem; /* 96px */
  --spacing-32: 7rem; /* 112px */
  --spacing-36: 8rem; /* 128px */
  --spacing-40: 9rem; /* 144px */
  --spacing-48: 10rem; /* 160px */
  --spacing-52: 11rem; /* 176px */

  --text-xs: 0.75rem; /* 12px */
  --text-sm: 0.875rem; /* 14px */
  --text-base: 1rem; /* 16px */
  --text-lg: 1.125rem; /* 18px */
  --text-xl: 1.25rem; /* 20px */
  --text-2xl: 1.5rem; /* 24px */
  --text-3xl: 1.875rem; /* 30px */
  --text-4xl: 2.25rem; /* 36px */
  --text-5xl: 3rem; /* 48px */
  --text-6xl: 3.75rem; /* 60px */
  --text-7xl: 4.5rem; /* 72px */
  --text-8xl: 6rem; /* 96px */
  --text-9xl: 8rem; /* 128px */

  --font-weight-standard: 200;
}
