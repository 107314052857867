.card-button {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: var(--spacing-2);
  background-color: white;
  color: var(--font-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: var(--box-shadow-cards);
  &:hover {
    scale: var(--hover-scale);
  }

  .icon {
    width: 100%;
    height: 42px;
    margin-bottom: 14px;
    font-size: 42px;
  }

  .text {
    font-size: 18px;
  }
}
