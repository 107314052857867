h1 {
  font-size: 36px;

  @media screen and (min-width: 800px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1100px) {
    font-size: 52px;
  }

  font-weight: 700;

  color: var(--font-color);
}

h2 {
  font-size: 30px;
  font-weight: 500;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

.subtitle {
  color: var(--sub-title-color);
}

.h3 {
}

.h4 {
}

.h1,
h2,
h3,
h4,
h5,
.p {
  margin: 0;
}

a {
  color: var(--link-color);
}
p,
li,
div,
span,
a {
  font-weight: 200;
}

.primary-text {
  color: var(--primary-color);
}
